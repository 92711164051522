body {
    background-color: #fff8e3;
}

.NavBar {
    display: flex;
    height: 5rem;
    align-items: center;
    position: fixed;
    z-index: 100;
    background-color: #fff8e3;
}
