.App {
    text-align: center;
}

.DContainer {
    width: calc(100vw - 48px) !important;
    max-width: 1152px !important;
}

body {
    background-color: #fff8e3;
}

/* For Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* For Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
