.Cup svg path {
    stroke: black;
    transition: all 0.3s;
}

/* .ThreeCupRow svg:hover path {
    stroke: red;
} */

.LidBase,
.LidTop {
    fill: rgb(0, 0, 0, 0);
    transition: 0.3s;
}

.Cup:hover .LidBase {
    fill: #784309;
}
.Cup:hover .LidTop {
    fill: #b46206;
}

.Cup:hover {
    animation: shake 0.3s;
    /* animation-iteration-count: infinite; */
}

@keyframes shake {
    0% {
        transform: rotateZ(0deg);
    }
    25% {
        transform: rotateZ(10deg);
    }
    50% {
        transform: rotateZ(00deg);
    }
    75% {
        transform: rotateZ(-10deg);
    }
    100% {
        transform: rotateZ(0deg);
    }
}

.CupFloat1 {
    animation: rise1 1.5s ease-out forwards, float11 2s ease-in-out 1.5s,
        float12 2s ease-in-out 3.5s, float13 2s ease-in-out 5.5s forwards;
    opacity: 0;
}
.CupFloat2 {
    animation: rise2 1s ease-out forwards, float21 2s ease-in-out 1s,
        float22 2s ease-in-out 3s, float23 2s ease-in-out 5s forwards;
    opacity: 0;
}

@keyframes rise1 {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(-10%);
        opacity: 1;
    }
}
@keyframes rise2 {
    from {
        transform: translateY(100%);
        opacity: 0.8;
    }
    to {
        transform: translateY(20%);
        opacity: 1;
    }
}

@keyframes float11 {
    0% {
        transform: translateY(-10%);
    }
    50% {
        transform: translateY(8%);
    }
    100% {
        transform: translateY(-6%);
    }
}
@keyframes float12 {
    0% {
        transform: translateY(-6%);
    }
    50% {
        transform: translateY(4%);
    }
    100% {
        transform: translateY(-4%);
    }
}
@keyframes float13 {
    0% {
        transform: translateY(-4%);
    }
    50% {
        transform: translateY(2%);
    }
    100% {
        transform: translateY(0%);
    }
}

@keyframes float21 {
    0% {
        transform: translateY(20%);
    }
    50% {
        transform: translateY(38%);
    }
    100% {
        transform: translateY(24%);
    }
}
@keyframes float22 {
    0% {
        transform: translateY(24%);
    }
    50% {
        transform: translateY(34%);
    }
    100% {
        transform: translateY(26%);
    }
}
@keyframes float23 {
    0% {
        transform: translateY(26%);
    }
    50% {
        transform: translateY(32%);
    }
    100% {
        transform: translateY(30%);
    }
}

.ThreeCups {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.ContactUsBody {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap-reverse;
}

a {
    text-decoration: none;
    color: white;
    cursor: pointer;
}

.QueryForm {
    width: min(95vw, 500px);
}

@media (max-width: 599px) {
    .QueryForm {
        width: 100%;
    }
}
